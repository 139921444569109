.emp_li:hover {
  background-color: #f24f2b;
  cursor: pointer;
}
.emp_list_menu {
  border: 0.5px solid black;
  margin-top: 5px;
  background-color: white;
  box-shadow: 0px 0px 3px 0px black;
}
.abs {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.abs .tooltiptext {
  visibility: hidden;
  /* width: inherit !important; */
  background-color: rgb(223, 219, 219);
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 6px;
  padding: 5px 3px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -6px;
  left: 115%;
  width: 203px;
  height: 48px;
}
.tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: -5px;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.abs {
  width: inherit;
}

.abs:hover .tooltiptext {
  visibility: visible;
}
