.add-emp-btn {
  background-color: #2f3c4e;
  color: white;
  border: 1px solid #2f3c4e;
  margin-left: 15px;
  padding: 5px 10px;
  border-radius: 7px;
}
.add-emp-btn:hover {
  cursor: pointer;
  background-color: white;
  border: 1px solid #2f3c4e;
  color: #2f3c4e !important;
}
.download_csv_txt {
  color: white;
}
.download_csv_txt:hover {
  color: #2f3c4e;
}
.csv_back_btn {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  float: right;
}
