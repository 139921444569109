.main{
    display: flex;
    justify-content: space-evenly;
}
.bottom{
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0rem;
    align-items: center;
}
.main__btm{
    justify-content : flex-start !important ;
}
.main__btm button{
    margin: 1px 4px;
}
.red_bg_row{background-color: red !important;color: white;}

.table_show{
    position: relative;
}
.table_show .table_head{
    position: sticky;
    top: 0;
   background-color: #fff;
   box-shadow: 0px -1px #d9d9d9;
}
.tableHeightLen{
    max-height: 500px;
}
.tableHeightSho{
    max-height: 350px;
}
.color_purpl{
    background-color: #D3C1FA;
}
.inv_clr{
    background-color: #8caccfd1;
}
.yellow_clr{
    background-color: #ffe586;
}
.yellow_drk{
    background-color: #ffd53b;
}
.gray_drk{
    background-color: #75746fbf;
}
.grn_lyt{
    background-color: #b9f59ee6;
}
.yellow_ligt{
    background-color: #efff00bf;
}

